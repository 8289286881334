import _ from 'lodash';
import { Class } from './class';

/**
 * Routes the application to a given path by pushing to the window history.
 * Data pushed to the history is wrapped in a container to ensure type safety on retrieval.
 *
 * @param path path to navigate to (can be relative).
 * @param data context data that will be pushed into the history to be used later. Can be queried by calling {@link retrieveHistoryData}.
 */
export function navigate<T extends HistoryData>(path: string, data?: T) {
  let container: HistoryDataContainer<T> | undefined;

  if (!_.isNil(data)) {
    container = {
      type: data.constructor.name,
      data: data,
    };
  }

  window.history.pushState(container, '', '#' + path);
  dispatchRouterNavigateEvent(path);
}

export function dispatchRouterNavigateEvent(path: string = getPath()) {
  const event = new CustomEvent('wtt.router.navigate', { detail: path });
  window.dispatchEvent(event);
}

export function reload() {
  window.location.reload();
}

/**
 * Retrieves data from the current window history state and converts it to an instance of {@link HistoryData}.
 *
 * @param clazz Desired type of the data. If type within state does not match this, the return value will be undefined.
 * @return an object if the state contains valid data of given type; otherwise undefined
 */
export function retrieveHistoryData<T extends HistoryData>(clazz: Class<T>): T | null {
  const state = window.history.state;

  if (!isHistoryDataContainer(state)) {
    return null;
  }

  if (state.type === clazz.name) {
    const data = new clazz();
    Object.assign(data, state.data);

    return data;
  } else {
    return null;
  }
}

export function initSpaRouting() {
  if (_.isEmpty(window.location.hash)) {
    if (window.location.pathname.endsWith('/')) {
      window.location.hash = '#/';
    } else {
      window.history.pushState(undefined, '', window.location.pathname + '/#/');
    }
  }

  window.addEventListener('popstate', () => dispatchRouterNavigateEvent());
  window.addEventListener('locationchange', () => dispatchRouterNavigateEvent());
  window.addEventListener('hashchange', () => dispatchRouterNavigateEvent());
}

export function getPath() {
  const hashPath = window.location.hash.substring(1);

  if (_.isEmpty(hashPath)) {
    return '/';
  }

  return hashPath.split('?')[0];
}

export function glob(pattern: string, path: string) {
  const re = new RegExp(
    '^' +
    pattern
      .replace(/([.?+^$[\]\\(){}|\/-])/g, '\\$1')
      .replace(/\*/g, '.*')
    + '$',
  )

  return re.test(path.toString());
}

export function getQueryParam(queryParam: string) {
  const queryParams = window.location.hash.split('?')[1];
  if (queryParams) {
    const urlSearchParams = new URLSearchParams(queryParams);
    return urlSearchParams.get(queryParam);
  }
}

/**
 * Abstract class for storing and retrieving data within the window history.
 */
export abstract class HistoryData {
}

/**
 *
 */
interface HistoryDataContainer<T extends HistoryData> {
  type: string,
  data: T
}

/**
 * Type guard for {@link HistoryDataContainer}
 * @param o
 */
function isHistoryDataContainer(o: any): o is HistoryDataContainer<any> {
  if (_.isNil(o)) {
    return false;
  }

  return o.hasOwnProperty('type') && o.hasOwnProperty('data');
}

